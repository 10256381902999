import request from '@/utils/request'
import qs from 'qs'

const private_key =
  '-----BEGIN PRIVATE KEY-----\n' +
  'MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAIG4MvsQ+4Ssyy2zUNLIAbI9IdeZs4gyeA12dXFpaQ7d9fs4NMTqtz1TubRNktZX3rji7jY7Fum8cKYus4OeyEBxnSLqNPDrkdq49aFBE6ROrVUzxuN6cgzwEqox7fqV8isyRCFAaf56LAGAeJ94Sq8nLIW6th86boPqemizNwlnAgMBAAECgYBOme2SQh0VrJpEq9X6kqSseuxptsGZcnNaPQA241d8YbRuMAWRmGPF7lilTyf9ZysWvrL2+2EL6lob6EpwI6NUwBobgazZA9ivuG2R/57HA/F5AGO6FbrWXwBCt+NHDWhzguYU/pwLRdGR1FB4i69Pie4ioBsIH7I6EDiZq/hfuQJBAN4AtNwcyOs/0NO8hyu2NjrE2kIKKKz92oCdaMkAo+K4NAR8Vss/nYzZPEYUGF1gjmB1x71a9qYGm+wcyCgVhNUCQQCVlancgUhW2Syxex/+ZbJk2SND6dpI4WuzjJS+akle4Ez2DxCcfvBqc8+3qR7HeJObh8MqKx+j2yc5po7UwCNLAkBetMJzHDyCT0kQ6t5nQWZFD/FkNt0/X1Q1hfFLvmAruhVGzJxiPJNDoPDbnHsAas6SS+yX1RRfo3Yk9epVX0i5AkEAhMPpl8VHp43qlTxrESjCRbBUFJsjGzy4kwxbmQZNSOBcbqjAXaZtTI6t9p0srG7g1FKTboIH+y8wiYLDkyFWMQJAL/5Qv1WPkOgGChLQpskrcJsmKJR3hr8zzVAliQxkxhq/tXzagWN2Wj+WPWoZZbOpfTUvOROaFj83/Rwk14ydtQ==\n' +
  '-----END PRIVATE KEY-----'

function encryptData(data) {
  let string = ''
  for (const key in data) {
    if (data[key] !== undefined && data[key] !== '') {
      string = `${string}&${key}=${data[key]}`
    }
  }
  string = string.substring(1)
  const forge = window.forge
  const privKey = forge.pki.privateKeyFromPem(private_key)
  const md = forge.md.sha256.create()
  const _buffer = string
  md.update(_buffer, 'utf8')
  const sig = privKey.sign(md)
  const erg = forge.util.encode64(sig)
  return erg
}

export function openCreate(data) {
  return request({
    url: '/pay/pay/openCreate',
    method: 'post',
    headers: {
      'X-OPREAL-AccessToken': '128u9asjdnmi9o12u389sad',
      'X-OPREAL-Signature': encryptData(data),
    },
    data,
  })
}

export function openGet(data) {
  return request({
    url: '/pay/orderInfo/openGet',
    headers: {
      'X-OPREAL-AccessToken': '128u9asjdnmi9o12u389sad',
      'X-OPREAL-Signature': encryptData(data),
    },
    method: 'post',
    data,
  })
}

export function customsDeclare(data) {
  return request({
    url: '/pay/customsDeclare/declare',
    headers: {
      'X-OPREAL-AccessToken': '128u9asjdnmi9o12u389sad',
      'X-OPREAL-Signature': encryptData(data),
    },
    method: 'post',
    data,
  })
}

export function openClose(data) {
  return request({
    url: '/pay/pay/openClose',
    headers: {
      'X-OPREAL-AccessToken': '128u9asjdnmi9o12u389sad',
      'X-OPREAL-Signature': encryptData(data),
    },
    method: 'post',
    data,
  })
}
